//Resize textarea on input
(function ($) {
    var $input = $( ":input" );
    $input.on('change keyup', function() {
        if ($(this).val() !== '') {
            $(this).addClass('isUsed');
        } else {
            $(this).removeClass('isUsed');
        }
    });

    if($('#msg').length){
        
        var ta = document.getElementById('msg');

        ta.addEventListener('input', upHeight);

        function upHeight() {
            ta.style.height = 'auto';
            ta.style.height = ta.scrollHeight + 2 + 'px';
        };
    };
})(jQuery);