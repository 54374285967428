global.jQuery = global.$ = require("./vendor/jquery-1.11.1.min.js");
require("./plugins/jquery.mmenu.min.all.js");
require("./modules/init.js");
require("./modules/dropdown.js");
require("./modules/slidetoggle.js");
require("./modules/matform.js");
require("./modules/tabs.js");
require("./modules/para-header.js");
require('./modules/forms');


(function ($) {

    var t = $('#nav-toggle');
    var n = $('#side-nav');

    t.click(function () {
        n.slideToggle();
    });

})(jQuery);

$('.js-close-notice').on('click', function(){
    $('.header__top').hide();
})
