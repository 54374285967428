(function ($) {
    
    var t = $('.btn-toggle');
    var ln = $('.lang-nav');
    var d = $(document);
    
    t.click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        ln.addClass('toggled');
    });
    
    d.click(function(e){
       ln.removeClass('toggled'); 
    });
    
})(jQuery);