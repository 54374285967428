(function ($) {

    var t = $('.top-visual');
    var th = $('header');
    var bird = $('#bird');
    var clouds = $('.cloud');
    var w = $(document).width();
    var h = t.outerHeight();

    t.mousemove(function (e) {
        moveImg(e);
    });

    th.mousemove(function (e) {
        moveImg(e);
    });

    function moveImg(e) {
        var xPos = e.pageX;
        var yPos = e.pageY;

        var moveX = Math.floor(xPos / w * 100);
        var moveY = Math.floor(yPos / h * 100);

        moveXinv = (100 - moveX) - 50;
        moveYinv = (100 - moveY) - 50;

        //  bird.css('transform', 'translate3d(' + moveXinv / 5 + '%, ' + moveYinv / 5 + '%, 0)');
        clouds.css('transform', 'translate3d(' + moveXinv / 3 + '%, ' + moveYinv / 5 + '%, 0)');
    }

})(jQuery);
