
//Content Accordion on Homepage
(function ($) {

    var mat = $('.ma-title');
    var mab = $('.ma-block');
    
    mat.click(function(){
        $(this).next('.slide-wrap').slideToggle(200);
        $(this).parents('.ma-block').toggleClass('open');
    });

})(jQuery);

//Content Accordion on FAQ page
(function ($) {

    var ft = $('.faq-title');
    var fc = $('.faq-content');
    
    ft.click(function(){
        $(this).next('.faq-content').slideToggle(200);
        $(this).toggleClass('open');
    });

})(jQuery);