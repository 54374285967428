(function ($) {
    
    $("#mobile-nav").mmenu({
        "offCanvas": {
            "position": "right",
            "zposition": "front"
        },
        "navbar": {
            "title": false
        }
    });
    
    var s = $('.btn-scroll');
    var vh = $('.top-visual').outerHeight();
    var btt = $('#btt');
    
    s.click(function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop: vh});
    });
    
    btt.click(function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop: 0});
    });
    
})(jQuery);