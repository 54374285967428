(function($){

    var tab = $('.tab-list li');

    function toggleTab(){
        tab.removeClass('open');
        $(this).addClass('open');
        $('.inner-tab').removeClass('open');

        if($(this).hasClass('tab1')){
            $('.inner-tab.tab1').addClass('open');
        }
        if($(this).hasClass('tab2')){
            $('.inner-tab.tab2').addClass('open');
        }
        if($(this).hasClass('tab3')){
            $('.inner-tab.tab3').addClass('open');
        }
    }

    tab.click(toggleTab);

})(jQuery);
