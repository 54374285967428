(function ($) {

var UIController = (function(){

  var initialText;

  function deleteErrors(el){
    var elements =  document.getElementsByClassName('form__error-message');
    if (typeof(elements) != 'undefined' && elements != null) {
        $(elements).remove();
    }
  }

  function addBtnClass(btn, btnClass){
    btn.removeClass('isLoading').addClass(btnClass);
    setTimeout(function(){
      btn.removeClass(btnClass);
    }, 3000);
  }

  function resetReviewForm(){
    $('.rating__userscore').raty('score', 0);
    $("input[name='score']").val(null);
    $('.rating__userscore img').removeClass('selected');
    $('.rating__caption').text(initialText);
  }

  return {

    getData: function(el){
      var formdata = {
        routingTarget: Routing.generate('site_frontend_zoho_call'),
        method: 'post',
        input: $(el).serializeArray()
      }
      return formdata;
    },

    getReviewData: function(el){
      var input = $(el).serializeArray();
      var formdata = {
        rating: input[0].value,
        name: input[1].value,
        email: input[2].value,
        message: input[3].value
      }
      return formdata;
    },

    handleSuccess: function(r, el, btn){
      deleteErrors(el);
      $(el)[0].reset();
      addBtnClass(btn, 'loadingSuccess');
      btn.before('<p class="form__succes-message" style="color: green;">'+ r.message +'</p>');

      if($(el).hasClass('review__form')){
        resetReviewForm();
      }
    },

    handleError: function(r, el, btn){
      deleteErrors(el);
      addBtnClass(btn, 'loadingError');

      if (r.errors) {
        $.map(r.errors, function(error, field) {
          $('#' + field).parent().append('<p class="form__error-message" style="color: red;">'+ error +'</p>');
        });
      }

      if (r.message) {
          btn.before('<p class="form__error-message" style="color: red;">'+ r.message +'</p>');
      }
    },

    beforeSend: function(btn){
      btn.addClass('isLoading');
    },

    setInitialText: function(){
      initialText = document.querySelector('.rating__caption').textContent;
    }

  }

})();

var formController = (function(){

  return {

    sendMail: function(data, callback, beforeSend){
      return $.ajax({
          method: data.method,
          url: data.routingTarget,
          beforeSend: beforeSend,
          data: {
              entity: 'contact',
              form: data.input
          },
          success: callback,
          error: callback
      });

    },

    submitReview: function(data, callback, beforeSend){
      var url = Routing.generate('site_frontend_ajax_call_reviews');
      return $.ajax({
          type: "POST",
          dataType: 'json',
          url: url,
          beforeSend: beforeSend,
          data: data,
          success: callback,
          error: callback
      });
    }

  }

})();


var controller = (function(formController, UIController){

  function handleSubmit(e){
    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('.js-form-submit');
    var data = UIController.getData(el);

    formController.sendMail(data, formCallback, UIController.beforeSend(btn));

    function formCallback(r){
      if (r.status === 'ok'){
        UIController.handleSuccess(r, el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    };

  }

  function handleReviewSubmit(e){
    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('.btn--review');
    var data = UIController.getReviewData(el);

    formController.submitReview(data, reviewCallback, UIController.beforeSend(btn));

    function reviewCallback(r){
      if (r.status === 'succes'){
        UIController.handleSuccess(r, el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    };

  }

  return {
    init: function(){
      var form   = $('.form-contact');
      var reviewForm = $('.review__form');

      if (form.length) {
          form.each(function () {
              $(this).on('submit', handleSubmit)
          });
      }
      if (reviewForm.length) {
        UIController.setInitialText();
          reviewForm.each(function () {
              $(this).on('submit', handleReviewSubmit)
          });
      }
    }
  }

})(formController, UIController);

controller.init();

})(jQuery);
